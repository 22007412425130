import { Outlet } from "react-router";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsalAuthentication } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import EnsureAuthToken from "./utils/auth/EnsureAuthToken";
import MainMenu from "./containers/MainMenu";
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from "./utils/appInsights";

const Layout = () => {

    // redirect to login if not authenticated
    useMsalAuthentication(InteractionType.Redirect);

    return (
        <div>
            <AppInsightsContext.Provider value={reactPlugin}>
                <AuthenticatedTemplate>
                    <EnsureAuthToken>
                        <>
                            <MainMenu />
                            <Outlet />
                        </>
                    </EnsureAuthToken>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <p>Redirecting to login...</p>
                </UnauthenticatedTemplate>              
            </AppInsightsContext.Provider>            
        </div>
    );
}

export default Layout;