/* https://docs.microsoft.com/en-us/azure/azure-monitor/app/javascript-react-plugin */
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
// import { createBrowserHistory } from 'history';

// const browserHistory = createBrowserHistory(/* { basename: '' } */);
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey:
      window.location.host === 'filer.tradesolution.no' 
      ? '8528069c-5c37-40c1-8409-54895aea1a62'
      : '3d64978e-be6d-41a9-922f-b939075039c1',
    extensions: [reactPlugin],
    // extensionConfig: {
    //   [reactPlugin.identifier]: { history: browserHistory },
    // },
  },
});
appInsights.loadAppInsights();
export { reactPlugin, appInsights };
