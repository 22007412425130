export const msalConfig = {
    auth: {
        clientId: "1ae70a68-8261-44cf-a767-86b8361e241b", // ApplicationId som en finner under App Registrations i Azure AD (trades.no)
        authority: "https://login.microsoftonline.com/trades.no", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
        redirectUri: "/",
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: ["https://trades.no/TradesolutionApi/user_impersonation"]
};