
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import '@tradesolution/iceberg-styles';
import './index.scss';
import { PublicClientApplication } from '@azure/msal-browser';
import Layout from './Layout';
import { msalConfig } from './utils/auth/authConfig';
import Home from './containers/Home';
import NotFound from './containers/NotFound';
import { MsalProvider } from '@azure/msal-react';

const msalInstance = new PublicClientApplication(msalConfig);
const container:any = document.getElementById('root');
const root = createRoot(container);
root.render(
  <MsalProvider instance={msalInstance}>
    <BrowserRouter>
            <Routes>
              <Route path='/' element={<Layout />}>
                <Route index element={<Home />} />
                <Route path="*" element={<NotFound />} />
              </Route>                
            </Routes>            
    </BrowserRouter>
  </MsalProvider>
);

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
if (process.env.NODE_ENV === 'production') {
  require('offline-plugin/runtime').install(); // eslint-disable-line global-require
}