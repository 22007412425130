import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { IcebergProductMenu, useSelectedTsKunde, IcebergIcon, IcebergCustomerPicker, IcebergNotifications } from '@tradesolution/iceberg-ui-react';
import Style from './index.module.css';
import { useMsal } from '@azure/msal-react';

interface Props {

}

const MainMenu = () => {
  const { clearSelectedTsKunde } = useSelectedTsKunde();
  const { instance, accounts } = useMsal();
  const name = accounts[0] && accounts[0].name;

  const getFirstName = (fullname: string | undefined): string => {
    if (!fullname) {
      return "";
    }
    return fullname.split(' ')[0];
  };

  const handleUserLoggedOut = () => {
    clearSelectedTsKunde();
    instance.logoutRedirect().catch(e => {
      console.error(e);
    });
  }

  return (
    <Navbar fixed="top" className={Style.navbar} collapseOnSelect expand="lg">
      <Container fluid>
        <Navbar.Brand href="/">
          <IcebergIcon icon='filer' />
          <span style={{ marginLeft: '0.5em' }}>Filer</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse>
          <Nav className="me-auto">
            <NavDropdown
              title={<IcebergIcon icon='meatballs' />}
              className='noCaret'>
              <IcebergProductMenu />
            </NavDropdown>
          </Nav>
          <Nav>
            <NavDropdown
              id="dropdownUserMenu"
              align="end"
              title={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginLeft: '0.5em' }}>{getFirstName(name)}</span>
                </div>
              }
            >
              <NavDropdown.Item
                onClick={() => handleUserLoggedOut()}
              >
                <span><strong>Logg av</strong></span>
              </NavDropdown.Item>
            </NavDropdown>
            <IcebergCustomerPicker />
            <IcebergNotifications />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar >
  );
}

export default MainMenu;