import axios from 'axios';
import { Permission } from './types.interface';
export class KundeportalenApi {
  baseUrl = 'https://kundeportalenapi.tradesolution.no/api';

  constructor() {
    if (      
      window.location.host.indexOf('dev') > -1
    ) {
      this.baseUrl = 'https://kundeportalenapi-dev.azurewebsites.net/api';
    }

    if (window.location.host.indexOf('localhost') > -1) {
      //this.baseUrl = 'https://localhost:5001/api';
      this.baseUrl = 'https://kundeportalenapi-dev.azurewebsites.net/api';
    }
  }

  fetchPermissions = () : Promise<Permission[]> => {
    return axios
    .get(`${this.baseUrl}/permissions/me`)
    .then((response) => response.data);
  }
}
