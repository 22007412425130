import React, { useState, useEffect, useMemo } from 'react';
import { Accordion, Col, Row, Spinner, Table } from 'react-bootstrap';
import { appInsights } from '../../utils/appInsights';
import Style from './index.module.css';
import { TsDataExportApi } from '../../services/TsDataExportApi';
import { FilesDto, FoldersDto } from '../../services/TsDataExportApi/types';
import {format} from 'date-fns';
import { KundeportalenApi } from '../../services/KundeportalenApi';
import { useSelectedTsKunde } from '@tradesolution/iceberg-ui-react';
import { useMsal } from '@azure/msal-react';

interface Props {
}

const Home: React.FC<Props> = props => {
  const [folders, setFolders] = useState<FoldersDto>();
  const [files, setFiles] = useState<FilesDto>();
  const [openTab, setOpenTab] = useState<string>();  
  const [noFoldersMessage, setNoFoldersMessage] = useState<string>();
  const dataexportApi = useMemo(() => new TsDataExportApi(), []);
  const { selectedTsKunde } = useSelectedTsKunde();
  const { accounts } = useMsal();
  const [canAccessAzurePortal, setCanAccessAzurePortal] = useState<boolean>(false);
  
  const loadFiles = async (folder: string) => {    
    setFiles(undefined);    
    if(selectedTsKunde){
      const files = await dataexportApi.getFiles(selectedTsKunde.tsKundeId, folder);
      setFiles(files); 
    }      
  };

  const handleToggleTab = (folder:string) => {
    if(folder === openTab)
      setOpenTab(undefined);
    else
      setOpenTab(folder);
  }

  const openInNewTab = (url: string) => {
    const win = window.open(url, '_blank', '');
    if (win != null) {
      win.focus();
    }
  };

  const trackEvent = (name: string, url: string) => {
    appInsights.trackEvent({ name: 'FrontendFilNedlasting', properties: { TsKundeNavn: selectedTsKunde.tsKundeNavn, FilNavn: name, Url: url } })
  }

  useEffect(() => {
    let isMounted = true;
    const loadFolders = async (tsKundeId: string) => {      
      let response = await dataexportApi.getFolders(tsKundeId);
      setFolders(response);
      if(!response || !response.folders || response.folders.length === 0){
        setNoFoldersMessage("Du har ikke tilgang til noen filer");
      }else{
        if(openTab){
          loadFiles(openTab);
        }
      }

    };

    if (isMounted && selectedTsKunde) {
      loadFolders(selectedTsKunde.tsKundeId);
    }
    return () => { isMounted = false };
  }, [selectedTsKunde]);

  useEffect(() => {
    if(openTab){
      loadFiles(openTab);
    }
  }, [openTab]);

  useEffect(() => {    
    let isMounted = true;
    const loadPermissions = async () => {      
      const kundeportalApi = new KundeportalenApi();
      var result = await kundeportalApi.fetchPermissions();      
      var tradesolutionAnsatt = result.some(x => x.role === "UberAdmin" || x.role === "TradesolutionAnsatt");
      if(tradesolutionAnsatt)      
        setCanAccessAzurePortal(true);
    }
    if (isMounted && accounts) {      
      loadPermissions();
    }
    return () => { isMounted = false };
  }, [accounts]);
 
  return (
    <div className={Style.container}>
      <Row>
        <Col>
          <h1 className={Style.mainHeader}>Filer</h1>
        </Col>
      </Row>
      {
        canAccessAzurePortal && (
        <div className={Style.azureUrlRow}>
          <a href='#' onClick={() => openInNewTab('https://portal.azure.com/?quickstart=True#@tradesolution.no/dashboard/arm/subscriptions/fa928724-51c2-47ff-b064-aa5f6ff3b954/resourcegroups/dashboards/providers/microsoft.portal/dashboards/83436f49-4ab0-4fc2-9ed7-a9d102522056')}>
            Vis statistikk
          </a>
        </div>
      )}
      <Row>
        <Col>
          {noFoldersMessage && (
            <h2>{noFoldersMessage}</h2>
          )}
          <Accordion >
          {folders?.folders.map( (x, index) => (
            <Accordion.Item key={index.toString()} eventKey={index.toString()}>
            <Accordion.Header onClick={() => handleToggleTab(x.name)}>{x.name}</Accordion.Header>
            <Accordion.Body>
            {!files && (
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              )}
              <Table>
                <thead>
                  <tr>
                    <th>Fil</th>
                    <th>Sist endret</th>
                  </tr>
                </thead>
                <tbody>
                {files?.files.map((y, fileIndex) => (
                  <tr key={fileIndex}>
                    <td><a href={y.url} onClick={() => trackEvent(y.name, y.url)}>{y.name}</a></td>
                    <td>{format(y.lastModified, 'dd.MM.yyyy HH:mm:ss')}</td>
                  </tr> 
                ))}
                </tbody>
              </Table>
            </Accordion.Body>
          </Accordion.Item> 
          ))}
            </Accordion>            
          </Col>
      </Row>
    </div >
  );
}

export default Home;