import axios from 'axios';
import { FilesDto, FoldersDto } from './types';
export class TsDataExportApi {
  baseUrl = 'https://dataexportapi.tradesolution.no/api';
  environment = 'Production';
  constructor() {
    if (
      window.location.host !== 'filer.tradesolution.no' 
    ) {
      this.baseUrl = 'https://dataexportapi-dev.tradesolution.no/api';
      this.environment = 'Development';
    }

    if (window.location.host.indexOf('localhost') > -1) {
      //this.baseUrl = 'https://dataexportapi-dev.tradesolution.no/api';
      this.baseUrl = 'http://localhost:7071/api';
      this.environment = 'Development';
    }
  }

  getFolders = async (tsKundeId: string) : Promise<FoldersDto> => {
    const response = await axios.get(`${this.baseUrl}/Folders/${tsKundeId}?environment=${this.environment}`);
    return response.data;
  }      

  getFiles = async (tsKundeId: string, folderName: string) : Promise<FilesDto> => {
    var response = await axios.get(`${this.baseUrl}/Files/${tsKundeId}?folderName=${folderName}`);
    return response.data;
  }
}
